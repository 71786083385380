/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Nunito;
    src: url("components/assets/fonts/nunito.ttf") format('truetype');
    font-display: fallback;
}

* {
    font-family: Nunito, serif;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

*:focus {
    outline: 0;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6B0DBC;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6B0DBC;
}
